import './Cat.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Cat() {
  const [data, setData] = useState({
    info: [],
    ordem: [],
    start: [],
    servicos: [],
    pressurizador: [],
    filtros: [],
    dosagem: [],
    desmi: [],
    osmose: [],
    leito: [],
    afericao: [],
    ozonio: [],
    uv: [],
    recup_rejeito: [],
    analise_agua: [],
    treinamento: [],
    tecnicos: [],
    relatorio: []
  });

  const [catNumber, setCatNumber] = useState('');
  const [clientData, setClientData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoints = [
          'info', 'ordem', 'start', 'servicos', 'pressurizador', 'filtros', 'dosagem', 
          'desmi', 'osmose', 'leito', 'afericao', 'ozonio', 'uv', 'recup_rejeito', 
          'analise_agua', 'treinamento', 'tecnicos', 'relatorio'
        ];
        
        const response = await Promise.all(
          endpoints.map(endpoint => 
            axios.get(`https://permutioncat.fly.dev/${endpoint}/`)
              .then(res => ({ [endpoint]: res.data }))
          )
        );
        
        const newData = response.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setData(newData);
      } catch (error) {
        console.error('Erro ao carregar os dados:', error);
      }
    };

    fetchData();
  }, []);

  const handleCatNumberChange = (e) => {
    setCatNumber(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchClientData();
    }
  };

  const fetchClientData = () => {
    if (catNumber) {
      const filteredData = {};

      // Filtra os dados de cada endpoint com base no número da CAT
      for (const key in data) {
        filteredData[key] = data[key].filter(item => item.cat_number === catNumber);
      }

      setClientData(filteredData);
      setError(null); // Limpar erros caso a resposta seja bem-sucedida
    } else {
      setClientData(null); // Limpar os dados se o campo CAT for vazio
      setError("Por favor, insira um número de CAT válido.");
    }
  };

  return (
    <div className="catform">
      <div className="cattext">Visualização dos Dados</div>

      <div className="filter-section">
        <label htmlFor="catNumber">Número da CAT:</label>
        <input 
          type="text" 
          id="catNumber"
          value={catNumber}
          onChange={handleCatNumberChange} 
          onKeyDown={handleKeyDown}
          placeholder="Digite o número da CAT e pressione ENTER"
        />
      </div>

      {error && <div className="error-message">{error}</div>}

      {clientData && (
        <div className="data-section">
          <h2>Dados Filtrados</h2>
          {Object.keys(clientData).map((key) => (
            <div key={key}>
              <h3>{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
              <div className="info-fields">
                {clientData[key].map((item, index) => (
                  <div key={index} className="info-item">
                    {Object.keys(item).map((subKey) => (
                      <div key={subKey} className="input-group">
                        <input
                          type="text"
                          value={item[subKey]}
                          readOnly
                          placeholder={subKey.charAt(0).toUpperCase() + subKey.slice(1)}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Cat;